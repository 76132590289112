export default {
    FEATURED:{
        ITEMS: [
            {
                IMG_SRC: "assets/img/featured-1.png",
                TEXT_1: "Modi sit est",
                TEXT_2: "Quis excepturi porro totam sint earum quo nulla perspiciatis eius."
            },
            {
                IMG_SRC: "assets/img/featured-2.png",
                TEXT_1: "Unde praesentium sed",
                TEXT_2: "Voluptas vel esse repudiandae quo excepturi."
            },
            {
                IMG_SRC: "assets/img/featured-3.png",
                TEXT_1: "Pariatur explicabo vel",
                TEXT_2: "Velit veniam ipsa sit nihil blanditiis mollitia natus."
            },
            {
                IMG_SRC: "assets/img/featured-4.png",
                TEXT_1: "Nostrum qui quasi",
                TEXT_2: "Ratione hic sapiente nostrum doloremque illum nulla praesentium id"
            }
        ]
    }
}