export default {
    SERVICES: {
        TITLE : "WHAT WE CAN DO",
        TITLE_SMALL: " ",
        ITEMS: [
            {
                ICON_DATA: "bx bx-receipt",
                TITLE: "Consultancy in game development",
                BODY: "We can support your team by offering idea, feedback, solution to help you polish your masterpiece"
            },
            {
                ICON_DATA: "bx bx-cube-alt",
                TITLE: "Mobile game development",
                BODY: "We can make game following your idea and support to publish it to Appstore/GooglePlay"
            },
            {
                ICON_DATA: "bx bx-images",
                TITLE: "Web/app development",
                BODY: "We can also making web/app to support your marketing campaign, business ideas"
            },
            {
                ICON_DATA: "bx bx-shield",
                TITLE: "If you are student/newbie in mobile game development",
                BODY: "We're willingly to contributing back to mobile game community by offering support and guidedance for student/newbie in game development who wants to join the mobile game industry"
            },
        ],
    }
}