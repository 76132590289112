import React, { Component } from 'react';
import Data from '../../JsonData/Main/ContactData'
class Contact extends React.Component {
    render() {
      return (
        <section id="contact" class="contact section-bg">
      <div class="container">

        <div class="section-title">
          <h2>Contact</h2>
          <p>Magnam dolores commodi suscipit eius consequatur ex aliquid fuga eum quidem</p>
        </div>

        <div class="row">

          <div class="col-lg-4">
            <div class="info d-flex flex-column justify-content-center" data-aos="fade-right">
              <div class="address">
                <i class="bi bi-geo-alt"></i>
                <h4>{Data.CONTACT.ADDRESS_TEXT}</h4>
                <p>{Data.CONTACT.ADDRESS_DETAIL}</p>
              </div>

              <div class="email">
                <i class="bi bi-envelope"></i>
                <h4>{Data.CONTACT.EMAIL_TEXT}</h4>
                <p>{Data.CONTACT.EMAIL_DETAIL}</p>
              </div>

              <div class="phone">
                <i class="bi bi-phone"></i>
                <h4>{Data.CONTACT.PHONE_TEXT}</h4>
                <p>{Data.CONTACT.PHONE_DETAIL}</p>
              </div>

            </div>

          </div>

          <div class="col-lg-8 mt-5 mt-lg-0">

            <form action="forms/contact.php" method="post" role="form" class="php-email-form" data-aos="fade-left">
              <div class="row">
                <div class="col-md-6 form-group">
                  <input type="text" name="name" class="form-control" id="name" placeholder="Your Name" required/>
                </div>
                <div class="col-md-6 form-group mt-3 mt-md-0">
                  <input type="email" class="form-control" name="email" id="email" placeholder="Your Email" required/>
                </div>
              </div>
              <div class="form-group mt-3">
                <input type="text" class="form-control" name="subject" id="subject" placeholder="Subject" required/>
              </div>
              <div class="form-group mt-3">
                <textarea class="form-control" name="message" rows="5" placeholder="Message" required></textarea>
              </div>
              <div class="my-3">
                <div class="loading">Loading</div>
                <div class="error-message"></div>
                <div class="sent-message">Your message has been sent. Thank you!</div>
              </div>
              <div class="text-center"><button type="submit">Send Message</button></div>
            </form>

          </div>

        </div>

      </div>
    </section>
        );
    }
}

export default Contact;