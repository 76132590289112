export default {
    ABOUT: {
        TITLE: "About Us",
        TITLE_SMALL: " ",
        BODY_1: "More than 10 years of experiences in mobile game industry",
        BODY_2: "LittleRabbitGames is a fresh studio found in 2018 in Ho Chi Minh city, Vietnam by a team who loves creating mobile game. \n Our expertise are",
        BODY_ITEMS:[
            {
                TEXT: "Mobile games in iOS & Android platforms"
            },
            {
                TEXT: "HTML5 game with short/long session"
            },
            {
                TEXT: "Web and Mobile Application"
            },

        ],
        BODY_3: "We make game with a mission that bringing the wonderful experiences of life to everyone through our creations!", 
    }
}