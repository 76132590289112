export default {
    CONTACT: {
        TITLE: "Contact",
        TEXT_1: "Magnam dolores commodi suscipit eius consequatur ex aliquid fuga eum quidem",

        ADDRESS_TEXT: "Location: ",
        ADDRESS_DETAIL: "Hoc Mon District,Ho Chi Minh City",

        EMAIL_TEXT: "Email:",
        EMAIL_DETAIL: "contact@littlerabbit.games",

        PHONE_TEXT: "Call:",
        PHONE_DETAIL: "+1 5589 55488 55s",
    }
}