import React, { Component } from 'react';
import Data from '../JsonData/FooterData'
class Footer extends React.Component {
    render() {
      return (
<footer id="footer">

    <div class="footer-top">

      <div class="container">

        <div class="row justify-content-center">
          <div class="col-lg-6">
            <a href="#header" class="scrollto footer-logo"><img src="assets/img/hero-logo.png" alt=""/></a>
            <h3>{Data.FOOTER.STUDIO_NAME}</h3>
            <p>{Data.FOOTER.TEXT}</p>
          </div>
        </div>

        {/* <div class="row footer-newsletter justify-content-center">
          <div class="col-lg-6">
            <form action="" method="post">
              <input type="email" name="email" placeholder="Enter your Email"/><input type="submit" value="Subscribe"/>
            </form>
          </div>
        </div> */}

        <div class="social-links">
          {/* <a href="#" class="twitter"><i class="bx bxl-twitter"></i></a> */}
          <a href="https://www.facebook.com/Little-Rabbit-Studio-102489264448596" class="facebook"><i class="bx bxl-facebook"></i></a>
          {/* <a href="#" class="instagram"><i class="bx bxl-instagram"></i></a> */}
          {/* <a href="#" class="linkedin"><i class="bx bxl-linkedin"></i></a> */}
        </div>

      </div>
    </div>

    <div class="container footer-bottom clearfix">
      <div class="copyright">
        &copy; Copyright <strong><span>{Data.FOOTER.STUDIO_NAME}</span></strong>. All Rights Reserved
      </div>
      <div class="credits">
        {/* Designed by <a href="https://bootstrapmade.com/">BootstrapMade</a> */}
      </div>
    </div>
  </footer>
  );
}
}

export default Footer;