export default {
    TESTIMONIALS: {
        ITEMS:[
            {
                TEXT: "Proin iaculis purus consequat sem cure digni ssim donec porttitora entum suscipit rhoncus. Accusantium quam, ultricies eget id, aliquam eget nibh et. Maecen aliquam, risus at semper.",
                IMAGE_URL: "assets/img/testimonials/testimonials-1.jpg",
                NAME: "Saul Goodman",
                POSITION: "Ceo &amp; Founder"
            },
            {
                TEXT: "Export tempor illum tamen malis malis eram quae irure esse labore quem cillum quid cillum eram malis quorum velit fore eram velit sunt aliqua noster fugiat irure amet legam anim culpa.",
                IMAGE_URL: "assets/img/testimonials/testimonials-2.jpg",
                NAME: "Sara Wilsson",
                POSITION: "Designer"
            },
        ]
        
    }
}