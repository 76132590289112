export default {
    TEAM: {
        TITLE: "The Team",
        TEXT: "Game design is the act of deciding what a game should be - Jesse Schell",
        ITEMS: [
            {
                AVATAR_URL: "assets/img/team/Dante.png",
                TWITTER_LINK: "",
                FACEBOOK_LINK: "",
                INSTAGRAM_LINK: "",
                LINKEDIN_LINK: "",
                NAME: "Dante",
                POSITION: "Chief Executive Officer",
                TEXT: "It's the job to be done not the role to be filled"
            },
            {
                AVATAR_URL: "assets/img/team/Khoa.png",
                TWITTER_LINK: "",
                FACEBOOK_LINK: "",
                INSTAGRAM_LINK: "",
                LINKEDIN_LINK: "",
                NAME: "Khoa Dong",
                POSITION: "Chief Technology Officer",
                TEXT: "Everything in life is a game"
            },
            {
                AVATAR_URL: "assets/img/team/Hoa.png",
                TWITTER_LINK: "",
                FACEBOOK_LINK: "",
                INSTAGRAM_LINK: "",
                LINKEDIN_LINK: "",
                NAME: "Hoa Dinh",
                POSITION: "Chief Technology Officer",
                TEXT: "Happiness is when what you think, what you say and what you do are in harmony"
            },
        ]
    }
}