export default {
    CLIENTS: {
        ITEMS: [
            {
                LOGO_URL: "assets/img/clients/client-1.png"
            },
            {
                LOGO_URL: "assets/img/clients/client-2.png"
            },
            {
                LOGO_URL: "assets/img/clients/client-3.png"
            },
            {
                LOGO_URL: "assets/img/clients/client-4.png"
            },
            {
                LOGO_URL: "assets/img/clients/client-5.png"
            },
            {
                LOGO_URL: "assets/img/clients/client-6.png"
            },
        ]
    }
}