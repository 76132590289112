export default {
    PORTFOLIO:{
        TITLE: "Portfolio",
        TEXT: "Click on the icon to download from Appstore/GooglePlay or play directly in your browser",
        Item :[
            {
                title: "Bit Rolling 3D",
                productImgURL: "assets/img/portfolio/bitrolling3d.png",
                appstoreURL: "https://apps.apple.com/vn/app/bit-rolling-3d/id1573164242",
            },
            {
                title: "Magic Ball Idle",
                smallDescription: "Colorful balls with neon style",
                productImgURL: "assets/img/portfolio/mbi.png",
                appstoreURL: "https://apps.apple.com/vn/app/magic-ball-idle/id1477325191",
                // playstoreURL: "https://play.google.com/store",
            },
            {
                title: "Vancuum Hero",
                productImgURL: "assets/img/portfolio/vancuumhero.png",
                appstoreURL: "https://apps.apple.com/us/app/vacuum-hero/id1552530647",
            },
            {
                title: "Epic Ball Roller",
                productImgURL: "assets/img/portfolio/epicballroller.png",
                appstoreURL: "https://apps.apple.com/vn/app/paint-roller-color-race/id1503896231",
                // playstoreURL: "https://play.google.com/store/apps/details?id=com.littlerabbitgames.epicballrollerGP",
            },
            // {
            //     title: "Beats Shot",
            //     productImgURL: "assets/img/portfolio/beatsshot.png",
            //     appstoreURL: "https://apps.apple.com/vn/app/paint-roller-color-race/id1503896231",
            //     // playstoreURL: "https://play.google.com/store/apps/details?id=com.littlerabbitgames.epicballrollerGP",
            // },
            {
                title: "Bit Rolling",
                productImgURL: "assets/img/portfolio/bit_rolling.png",
                playDirectURL: "games/bitrolling/index.html",
            },
            {
                title: "Green Planet",
                productImgURL: "assets/img/portfolio/htx.png",
                playDirectURL: "games/hanhtinhxanh/index.html",
            },
        ],
    },
}